<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="设备编号">
                <a-input v-model="queryParam.devCode" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="水表编号">
                <a-input v-model="queryParam.devSn" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="日期范围">
                <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="7" :sm="24">
              <a-form-model-item label="日期" >
                <div style="display: flex;">
                  <a-select v-model="queryParam.dateType" placeholder="请选择" style="width: 60px;flex-shrink: 0;" @change="changeType">
                    <a-select-option v-for="(item,key) in [{name:'年',id:1},{name:'月',id:2},{name:'日',id:3}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                  </a-select>
                  <a-date-picker
                    style="flex-grow: 1;"
                    v-if="queryParam.dateType == 1"
                    mode="year"
                    format="YYYY"
                    :value="value1"
                    placeholder="请选择年"
                    :allowClear="false"
                    :open="yearShowOne"
                    @openChange="openChangeOne"
                    @panelChange="panelChangeOne"/>
                  <a-date-picker
                    style="flex-grow: 1;"
                    v-if="queryParam.dateType == 2"
                    mode="month"
                    format="YYYY-MM"
                    :value="value1 ? `${value1}-${value2}` : ''"
                    placeholder="请选择年月"
                    :allowClear="false"
                    :open="yearShowOne"
                    @openChange="openChangeOne"
                    @panelChange="panelChangeOne"/>
                  <a-date-picker
                    style="flex-grow: 1;"
                    v-if="queryParam.dateType == 3"
                    mode="date"
                    format="YYYY-MM-DD"
                    :value="value1 ? `${value1}-${value2}-${value3}` : ''"
                    placeholder="请选择年月日"
                    :allowClear="false"
                    :open="yearShowOne"
                    @openChange="openChangeOne"
                    @change="changeOne"/>
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button type="primary" @click="handleExport" v-hasPermi="['wisdom:devicesettleday:export:ls']">
          <a-icon type="download" />导出
        </a-button> -->
        <a-button type="primary" @click="openDownload()" v-hasPermi="['wisdom:devicesettleday:export:ls']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered">
        <span slot="userInfo" slot-scope="text, record">
          <div class="spanBox">
            <span>{{ record.userName }}</span>
            <span>{{ record.userPhone }}</span>
          </div>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <!-- 导出 -->
    <export
      ref="export"
      :typeOptions="dict.type['sys_yes_no']"
      @ok="getList"
    />
  </page-header-wrapper>
</template>

<script>

import { deviceHjPage } from '@/api/pages/statistics'
import { tableMixin } from '@/store/table-mixin'
import Export from './modules/export'

export default {
  name: 'Config',
  mixins: [tableMixin],
  dicts: ['sys_yes_no'],
  components: {
    Export
  },
  data () {
    return {
      yearShowOne: false,
      value1: '',
      value2: '',
      value3: '',
      list: [],
      loading: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        dateType: 1,
        devCode: null,
        devSn: null
      },
      columns: [
        {
          title: '设备编号',
          dataIndex: 'devCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '水表编号',
          dataIndex: 'devSn',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备类型',
          dataIndex: 'typeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '绑定人信息',
          dataIndex: 'userInfo',
          scopedSlots: { customRender: 'userInfo' },
          align: 'center'
        },
        {
          title: '总量',
          dataIndex: 'useWaterAll',
          ellipsis: true,
          align: 'center'
        }
        // {
        //   title: '上期总量',
        //   dataIndex: 'useWaterAllUp',
        //   ellipsis: true,
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    openDownload() {
      this.$refs.export.handle()
    },
    changeType(e) {
      this.queryParam.settleYear = undefined
      this.queryParam.settleMonth = undefined
      this.queryParam.settleDay = undefined
      this.value1 = ''
      this.value2 = ''
      this.value3 = ''
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false
      const arr = value.format('YYYY-MM-DD').split('-')
      console.log(arr)
      this.value1 = arr[0]
      this.value2 = arr[1]
      this.value3 = arr[2]
      if (this.queryParam.dateType === 1) {
        this.queryParam.settleYear = arr[0]
        this.queryParam.settleMonth = undefined
        this.queryParam.settleDay = undefined
      } else if (this.queryParam.dateType === 2) {
        this.queryParam.settleYear = arr[0]
        this.queryParam.settleMonth = arr[1]
        this.queryParam.settleDay = undefined
      } else if (this.queryParam.dateType === 3) {
        this.queryParam.settleYear = arr[0]
        this.queryParam.settleMonth = arr[1]
        this.queryParam.settleDay = arr[2]
      }
    },
    changeOne(value) {
      this.yearShowOne = false
      const arr = value.format('YYYY-MM-DD').split('-')
      console.log(arr)
      this.value1 = arr[0]
      this.value2 = arr[1]
      this.value3 = arr[2]
      this.queryParam.settleYear = arr[0]
      this.queryParam.settleMonth = arr[1]
      this.queryParam.settleDay = arr[2]
    },
    /** 查询参数列表 */
    getList () {
      if (this.dateRange !== null && this.dateRange !== '' && this.dateRange.length === 2) {
        this.queryParam.createBeginTime = this.dateRange[0] + ' 00:00:00'
        this.queryParam.createEndTime = this.dateRange[1] + ' 23:59:59'
      }
      this.loading = true
      deviceHjPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        dateType: 1,
        devCode: null,
        devSn: null
      }
      this.value1 = ''
      this.value2 = ''
      this.value3 = ''
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '提示',
        content: '是否确认导出?',
        onOk () {
          that.download('wisdom/wisdom-device-settle-day/export/ls', {
            ...that.queryParam
          }, `抄收历史_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>
